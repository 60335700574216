<template>
  <div>
    <div class="mb-6">
      <a
        class="text-decoration-none text-subtitle-2 font-weight-bold"
        @click.prevent="returnBack"
      >
        <v-icon
          color="primary"
          dense
          class="mr-2"
        >
          {{ icons.mdiArrowLeft }}
        </v-icon>
        Kembali
      </a>
    </div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :total-items="totalItems"
          :headers="headers"
          :search="search"
          :items="attendances"
          :page="page"
          :no-add-button="noAddButton"
          :filter-employee-attendance="filterEmployeeAttendance"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          title="Absensi Pegawai"
          subtitle="Halaman untuk absensi pegawai "
          @page="page"
          @show-result="showResultFilter"
          @change-page="paginationHandler"
          @live-search="searchHandler"
          @filter-from-date-atd="handleFilterFromDate"
          @filter-to-date-atd="handleFilterToDate"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :dialog="dialog"
      text-button="Cetak"
      :header="dialog === 'all'? 'Cetak Semua Absensi Pegawai' : 'Cetak Absensi Pegawai'"
      :is-loading-button="isLoadingButton"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <div v-if="dialog == 'all'">
          <v-menu
            :close-on-content-click="true"
            nudge-right="0"
            nudge-top="80"
            max-width="100%"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="recap.date"
                label="Bulan"
                :prepend-icon="icons.mdiCalendarMonthOutline"
                readonly
                outlined
                dense
                v-bind="attrs"
                clearable
                @click="autofillMonth"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="recap.date"
              type="month"
              locale="id-id"
              color="primary"
            ></v-date-picker>
          </v-menu>
        </div>
        <div v-if="dialog == 'single'">
          <div>
            <v-autocomplete
              v-model="recap.year"
              label="Pilih Tahun"
              outlined
              :items="years"
              item-text="title"
              item-value="value"
              dense
              clearable
              @change="handleYear($event)"
            ></v-autocomplete>
          </div>
        </div>
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import { mdiArrowLeft, mdiCalendarMonthOutline } from '@mdi/js'

export default {
  name: 'AttendanceStudentHeadMaster',
  components: {
    DataTablePagination,
    SkeletonLoaderTable,
    MainCard,
    ModalDialog,
  },
  data() {
    return {
      icons: {
        mdiCalendarMonthOutline,
        mdiArrowLeft,
      },
      dialog: '',

      class: '',
      modalDialog: false,
      confirmDialog: false,
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      printRecapButton: true,
      noAddButton: true,
      filterEmployeeAttendance: true,
      page: 1,
      totalPages: 1,
      totalItems: 1,
      service: 'attendanceemployee',
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Nama Lengkap', value: 'name' },
        { text: 'Status', value: 'isActive' },
        { text: 'Hadir (H)', value: 'hadir' },
        { text: 'Alfa (A)', value: 'alpha' },
        { text: 'Sakit (S)', value: 'sakit' },
        { text: 'Izin (I)', value: 'izin' },
      ],
      years: [
        { index: 1, title: '2020', value: '2020' },
        { index: 2, title: '2021', value: '2021' },
        { index: 3, title: '2022', value: '2022' },
        { index: 4, title: '2023', value: '2023' },
        { index: 5, title: '2024', value: '2024' },
        { index: 6, title: '2025', value: '2025' },
        { index: 7, title: '2026', value: '2026' },
        { index: 8, title: '2027', value: '2027' },
        { index: 9, title: '2028', value: '2028' },
        { index: 10, title: '2029', value: '2029' },
        { index: 11, title: '2030', value: '2030' },
      ],
      students: [],
      attendances: [],
      recap: {
        year: '',
        date: '',
      },
      school: '',
      school_uuid: '',
      search: '',
      employee_uuid: '',
      filterQuery: {
        start_date: '',
        end_date: '',
        search: '',
      },
    }
  },
  watch: {
    page: {
      handler() {
        this.listAttendanceEmployee()
      },
    },

    class: {
      handler() {
        this.listAttendanceEmployee({ class_uuid: this.class })
      },
    },
  },
  beforeMount() {
    this.getSchoolUuid(this.$route.params.uuid)
  },
  mounted() {
    this.listAttendanceEmployee()
    console.log(this.school_uuid)
  },
  methods: {
    getSchoolUuid(uuid) {
      this.school_uuid = uuid
    },
    async listAttendanceEmployee(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, {
        ...params,
        school_uuid: this.school_uuid,
        page: this.page,
      }).then(
        ({ data }) => {
          this.attendances = data.data.map((attendances, index) => ({
            ...attendances,
            index: index + 1,
          }))
          this.totalPages = data.meta.last_page
          this.totalItems = data.meta.total
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },
    showFormRecapAll() {
      this.dialog = 'all'
      this.resetForm()
      this.modalDialog = true
    },
    showFormRecapEmployee(uuid) {
      this.employee_uuid = uuid
      this.dialog = 'single'
      this.resetForm()
      this.modalDialog = true
    },
    handlerButton() {
      if (this.dialog === 'all') this.printRecapAll()
      else this.printRecap()
    },
    handleYear(year) {
      this.recap.year = year
    },
    autofillMonth() {
      this.recap.date = new Date().toISOString().substr(0, 7)
      console.log()
    },
    resetForm() {
      this.recap.date = ''
      this.recap.year = ''
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    searchHandler(search) {
      this.filterQuery.search = search
      this.page = 1
      this.listAttendanceEmployee(this.filterQuery)
    },
    handleFilterFromDate(date) {
      this.filterQuery.start_date = date
    },
    handleFilterToDate(date) {
      this.filterQuery.end_date = date
    },
    showResultFilter() {
      this.isLoadingButton = true
      this.listAttendanceEmployee({
        start_date: this.filterQuery.start_date,
        end_date: this.filterQuery.end_date,
      })
      this.isLoadingButton = false
    },
    returnBack() {
      this.$router.back()
    },
  },
}
</script>

<style>
</style>
